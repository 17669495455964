import * as React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="lost-page">
      <div className="content">
        <h1>Uh oh</h1>
        <h2>Looks like you're lost</h2>
        <button
          className="btn btn--section"
          onClick={() => { navigate("/") }}
        >
          Let's go back home
        </button>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
